import React, { useState } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Breadcrumbs, Button, Hero, UpdateCard } from "../components/common";
import Subscription from "../components/subscription";

import Mike from "../assets/images/vectors/mike.svg";
import { getStaticSEOImage } from "../helpers";

const UpdatesPage = ({ pageContext: { data: siteUpdates }, location }) => {
  const [state, setState] = useState({
    updatesToShow: 7,
    isLoading: false,
  });

  if (!siteUpdates?.length) {
    return null;
  }

  const visibleSiteUpdates = siteUpdates.slice(0, state.updatesToShow);
  const title = "What’s New?";
  const description =
    "Get all the news, updates, fixes, deletion of our products, collections, blog posts and website contents. It is our public diary of what we do on regular basis.";

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={title}
        description={description}
        image={getStaticSEOImage("site-updates")}
        path={location?.pathname}
      />

      <section className="md:mt-24 mt-12 mb-32">
        <Hero subTitle="Site Updates" title={title} description={description}>
          <Breadcrumbs
            lists={[
              {
                title: "Home",
                to: "/",
              },
              {
                title: "Updates",
                to: "/updates",
              },
            ]}
          />
        </Hero>
      </section>
      <section className="mb-40 mt-10 relative">
        <img
          className="absolute z-0"
          style={{
            bottom: "calc(100% - 8px)",
            left: "calc(50% - 56px)",
          }}
          src={Mike}
          alt="mike-vector"
        />
        <div className="flex flex-col md:gap-16 gap-12 lg:mx-40 mx-0 relative">
          {visibleSiteUpdates.map((siteUpdate, key) => (
            <UpdateCard
              key={key}
              tags={[
                {
                  title: siteUpdate?.attributes?.Tag || "New",
                  type: String(siteUpdate?.attributes?.Tag).toLowerCase(),
                },
              ]}
              date={siteUpdate?.attributes?.createdAt || "Unknown"}
              title={siteUpdate?.attributes.Title || ""}
              description={String(
                siteUpdate?.attributes?.Description || ""
              ).substring(0, 120)}
              actionTitle={siteUpdate?.attributes.ActionTitle || "Know More"}
              action={siteUpdate?.attributes.ActionTitleURL || "/about"}
              FeaturedImage={siteUpdate?.attributes?.FeaturedImage}
            />
          ))}
        </div>
        {siteUpdates.length > state.updatesToShow && (
          <div className="flex items-center justify-center mt-16">
            <Button
              icon="vg-arrow-right"
              onClick={() => {
                setState({ ...state, isLoading: true });

                setTimeout(() => {
                  setState({
                    ...state,
                    updatesToShow: state.updatesToShow + 7,
                    isLoading: false,
                  });
                }, 200);
              }}
              text="Load More Updates"
              iconRotate={90}
              isLoading={state.isLoading}
            />
          </div>
        )}
      </section>
      <section className="mb-24">
        <Subscription />
      </section>
    </Layout>
  );
};

UpdatesPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default UpdatesPage;
